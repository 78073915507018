import React from 'react';
import styles from '../styles/prices.module.scss';
import prices from '../../static/prices';

import Card from './card';

const Prices = () => {
  const cards = prices.map(({ id, title, price, list }) => {
    return <Card key={id} title={title} price={price} list={list} />;
  });

  return (
    <div className={styles.container}>
      <h2>Prices</h2>
      <div className={styles.cardsContainer}>{cards}</div>
    </div>
  );
};

export default Prices;
