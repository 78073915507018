import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import values from '../../static/values';
import Image from 'gatsby-image';

import TriangleSection from './triangleSection';
import TriangleContent from './triangleContent';

const ValuesSection = () => {
  const {
    allFile: { nodes: serviceImages },
  } = useStaticQuery(GET_SERVICE_IMAGES);

  const valuesSections = values.map(({ id, title, text, bg }, index) => {
    let direction = 'left';
    if (index % 2 === 0) direction = 'right';
    return (
      <TriangleSection key={id} direction={direction} bg={bg}>
        <TriangleContent direction={direction} title={title} text={text}>
          <Image
            fluid={serviceImages[index].childImageSharp.fluid}
            style={{ flexGrow: 1 }}
            alt="cleaning service"
          />
        </TriangleContent>
      </TriangleSection>
    );
  });

  return valuesSections;
};

const GET_SERVICE_IMAGES = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "src/images/services" } }) {
      nodes {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default ValuesSection;
