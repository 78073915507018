const values = [
  {
    id: 1,
    title: 'Guaranteed Satisfaction',
    bg: '#4D5C96',
    text:
      'Our primary concern is customer satisfaction. To ensure our customers are satisfied, we frequently check our service and carry out regular customer feedback to ensure all expectations are surpassed.',
  },
  {
    id: 2,
    title: 'Flexible',
    bg: '#2691ff',
    text:
      'Our clients range from single premise customers to those with multiple office sites. We have 15 years of knowledge, experience and expertise to resolve all your cleaning needs – no job is too big or too small, too dirty or too difficult.',
  },
  {
    id: 3,
    title: 'Excellence',
    bg: '#3F4E85',
    text:
      'Our staff are personally trained by Mira herself. We use top quality products and equipment to provide an expert finesse to your clean.',
  },
];

export default values;
