import React from 'react';
import styles from '../styles/triangleSection.module.scss';

const TriangleSection = ({ children, direction, bg }) => {
  const triangleStyles = [];
  let topTriangleColor;
  let sectionColor;
  let bottomTriangleColor;

  if (direction === 'right') {
    triangleStyles.push(styles.right);
    topTriangleColor = {
      borderColor: `transparent transparent ${bg} transparent`,
    };
    bottomTriangleColor = {
      borderColor: `transparent ${bg} transparent transparent`,
    };
    sectionColor = { backgroundColor: bg };
  } else {
    triangleStyles.push(styles.left);
    topTriangleColor = {
      borderColor: `transparent transparent ${bg} transparent`,
    };
    bottomTriangleColor = {
      borderColor: `transparent transparent transparent ${bg}`,
    };
    sectionColor = { backgroundColor: bg };
  }

  return (
    <div className={styles.container}>
      <span
        className={[...triangleStyles, styles.topTriangle].join(' ')}
        style={topTriangleColor}
      />
      <section className={styles.section} style={sectionColor}>
        {children}
      </section>
      <span
        className={[...triangleStyles, styles.bottomTriangle].join(' ')}
        style={bottomTriangleColor}
      />
    </div>
  );
};

export default TriangleSection;
