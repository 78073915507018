import React from 'react';
import styles from '../styles/input.module.scss';

const Input = ({ type, label, value, onChange, required }) => {
  return (
    <div className={styles.formControl}>
      <label htmlFor={label}>{label}</label>
      {type === 'textarea' ? (
        <textarea
          id={label}
          value={value}
          onChange={onChange}
          required={required}
        ></textarea>
      ) : (
        <input
          type={type}
          id={label}
          value={value}
          onChange={onChange}
          required={required}
        />
      )}
    </div>
  );
};

export default Input;
