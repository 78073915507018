import React from 'react';
import styles from '../styles/carousel.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/slickOverride.scss';
import Slider from 'react-slick';
import Quotes from '../../static/testimonials';
import Testimonial from './testimonial';

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const testimonials = Quotes.reverse().map(({ id, name, quote, date }) => (
    <Testimonial key={id} name={name} quote={quote} date={date} />
  ));

  return (
    <div className={styles.container}>
      <h2>What people say about us</h2>
      <div className={styles.content}>
        <Slider {...settings}>{testimonials}</Slider>
      </div>
    </div>
  );
};

export default Carousel;
