import React from 'react';
import styles from '../styles/card.module.scss';

const Card = ({ title, price, list }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <h3>{title}</h3>
        <p>{price}</p>
      </div>
      <div className={styles.cardContent}>
        <ul>
          {list.map((listItem, index) => (
            <li key={index}>{listItem}</li>
          ))}
        </ul>
        <div className={styles.cardFooter}>Contact us for a Free Quote!</div>
      </div>
    </div>
  );
};

export default Card;
