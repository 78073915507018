import React from 'react';
import styles from '../styles/layout.module.scss';

import Header from './header';

const Layout = ({ children }) => {
  return (
    <div className={styles.container}>
      <Header />
      {children}
    </div>
  );
};

export default Layout;
