import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import styles from '../styles/contact.module.scss';

import Input from './input';
import Button from './button';
import Loading from './loading';

import facebookIcon from '../images/icons/facebook.svg';
import whatsappIcon from '../images/icons/whatsapp.svg';
import emailIcon from '../images/icons/email.svg';
import phoneIcon from '../images/icons/phone.svg';
import mapIcon from '../images/icons/map.svg';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    const templateParams = { name, email, message };

    try {
      setEmailError(false);
      setEmailSent(false);
      setLoading(true);

      await emailjs.send(
        'mirasmaidswebsiteform',
        'template_lQVPmj8S',
        templateParams,
        'user_MHXDq0M88BCU2XMiUXKqI',
      );

      setLoading(false);
      setEmailSent(true);
      clearFormFields();
    } catch (err) {
      setLoading(false);
      setEmailError(true);
    }
  };

  const clearFormFields = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className={styles.container}>
      <h2>Contact us</h2>
      <p>
        We provide regular domestic and home cleaning in Folkestone, Ashford,
        Cheriton, Sandgate, Hythe, Lyminge, Hawkinge and the surrounding areas
        from just £15 per hour. Contact us for a free quote today!
      </p>
      <div className={styles.contentContainer}>
        <ul className={styles.contactInfo}>
          <li>
            <div>
              <img src={phoneIcon} alt="phone icon" />
            </div>
            <p>07578 505621 / 01303 257150</p>
          </li>
          <li>
            <div>
              <img src={emailIcon} alt="email icon" />
            </div>
            <p>
              <a href="mailto:mira.mirasmaids@outlook.com">mira.mirasmaids@outlook.com</a>
            </p>
          </li>
          <li>
            <div>
              <img src={facebookIcon} alt="facebook icon" />
            </div>
            <p>
              <a
                href="https://www.facebook.com/Miras-Maids-291456131352354"
                target="blank"
              >
                facebook.com/mirasmaids
              </a>
            </p>
          </li>
          <li>
            <div>
              <img src={whatsappIcon} alt="whatsapp icon" />
            </div>
            <p>07578 505621</p>
          </li>
          <li>
            <div>
              <img src={mapIcon} alt="map icon" />
            </div>
            <p>
              <a href="https://goo.gl/maps/XZGVF8j1wyAmnwNY9" target="blank">
                Folkestone, Kent
              </a>
            </p>
          </li>
        </ul>
        <form className={styles.contactForm} onSubmit={formSubmitHandler}>
          <Input
            type="text"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Input
            type="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="textarea"
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <div className={styles.submitSection}>
            <Button type="submit">send</Button>
            <div className={styles.emailStatus}>
              {loading && (
                <>
                  <Loading />
                  <p>Sending message...</p>
                </>
              )}
              {emailSent && <p>Message sent!</p>}
              {emailError && <p>Sending failed, please try again</p>}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
