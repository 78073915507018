import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styles from '../styles/header.module.scss';

import Image from 'gatsby-image';

const GET_LOGO = graphql`
  query {
    logo: file(relativePath: { eq: "static/logo.png" }) {
      childImageSharp {
        fixed(
          width: 120
          duotone: { highlight: "#098aed", shadow: "#00f2ff" }
        ) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Header = () => {
  const data = useStaticQuery(GET_LOGO);
  const imageData = data.logo.childImageSharp.fixed;

  return (
    <header className={styles.container}>
      <Image fixed={imageData} alt="mira's maids logo" />
    </header>
  );
};

export default Header;
