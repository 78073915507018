import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';

const GET_IMAGE = graphql`
  query {
    desktop: file(relativePath: { eq: "src/images/bg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const BackgroundSection = ({ className, children }) => {
  const data = useStaticQuery(GET_IMAGE);
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      alt="background cleaning"
    >
      {children}
    </BackgroundImage>
  );
};

export default BackgroundSection;
