import React from 'react';
import styles from '../styles/footer.module.scss';

const Footer = () => (
  <footer className={styles.footer}>
    <hr />© Mira's Maids - Home cleaning services
  </footer>
);

export default Footer;
