import React from 'react';
import '../styles/global.scss';

import SEO from '../components/seo';
import Layout from '../components/layout';
import BackgroundSection from '../components/backgroundImage';
import ValuesSection from '../components/valuesSection';
import TriangleSection from '../components/triangleSection';
import CustomAnimation from '../components/customAnimation';
import Prices from '../components/prices';
import Carousel from '../components/carousel';
import Contact from '../components/contact';
import Footer from '../components/footer';

export default () => {
  return (
    <Layout>
      <SEO />

      <BackgroundSection className="section-one">
        <section className="filter-bg">
          <div className="main-banner">
            <h1>
              <CustomAnimation text="Folkestone Cleaning Services" />
            </h1>
            <h2>
              With more than 15 years experience, Mira's Maids offer a variety
              of cleaning services in and around Folkestone
            </h2>
          </div>
        </section>
      </BackgroundSection>

      <ValuesSection />

      <TriangleSection direction="left" bg="#2f3140">
        <Prices />
      </TriangleSection>

      <TriangleSection direction="right" bg="#4D5C96" removeBottom>
        <Carousel />
      </TriangleSection>

      <BackgroundSection className="section-bottom">
        <section className="filter-bg dark">
          <Contact />
          <Footer />
        </section>
      </BackgroundSection>
    </Layout>
  );
};
