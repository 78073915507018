import React from 'react';
import styles from '../styles/testimonial.module.scss';

const Testimonial = ({ quote, name, date }) => {
  return (
    <div className={styles.quoteContainer}>
      <div className={styles.quote}>"{quote}"</div>
      <div className={styles.quoteFooter}>
        <span>{name}</span>
        <span> - {date}</span>
      </div>
    </div>
  );
};

export default Testimonial;
