import React from 'react';
import styles from '../styles/triangleContent.module.scss';

const TriangleContent = ({ title, text, children, direction }) => {
  const contentStyles = [styles.container];

  if (direction === 'right') {
    contentStyles.push(styles.right);
  } else {
    contentStyles.push(styles.left);
  }

  return (
    <div className={contentStyles.join(' ')}>
      <div className={styles.image}>{children}</div>
      <div className={styles.text}>
        <h2>{title}</h2>
        <hr />
        <p>{text}</p>
      </div>
    </div>
  );
};

export default TriangleContent;
