import React from 'react';
import styles from '../styles/loading.module.scss';

const Loading = () => (
  <div className={styles.ldsRing}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Loading;
