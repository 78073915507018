import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = () => {
  const { site } = useStaticQuery(query);

  const { title, description, siteUrl, keyWords, image } = site.siteMetadata;

  const seo = {
    title,
    description,
    url: siteUrl,
    keyWords,
    image: `${siteUrl}${image}`,
  };

  return (
    <Helmet title={seo.title} htmlAttributes={{ lang: 'en' }}>
      <meta name="description" content={seo.description} />

      {seo.image && <meta name="image" content={seo.image} />}

      <link rel="canonical" href={siteUrl} />

      {seo.keyWords && (
        <meta name="keywords" content={seo.keyWords.join(', ')} />
      )}

      {seo.title && <meta property="og:title" content={seo.title} />}

      <meta property="og:type" content="website" />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta property="og:locale" content="en_GB" />

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      <meta property="og:site_name" content={seo.title} />
      <meta name="twitter:site" content={seo.url} />
      <meta name="twitter:image:alt" content={seo.title} />

      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        siteUrl
        keyWords
        image
      }
    }
  }
`;
